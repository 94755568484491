
.btn-primary{
  background-color: #002335!important;
  color: white;
}

.btn-secondary,
.btn-secondary .text-secondary{
  background-color: #FF784B!important;
  color: white!important;
}

.btn-outline-secondary{
  color: #FF784B!important;
  border: 2px solid #FF784B!important;
}

.outline-secondary{
  border: 2px solid #FF784B!important;
}

.outline-light{
  border: 2px solid #ffffff!important;
}

.outline-primary{
  border: 2px solid #002335!important;
}


.btn-light{
  background-color: #ffffff;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.2);
  color: #002335!important;
}

.active-border{
  border: 2px solid #FF784B!important;
}

.ico-disabled{
  opacity: 0.5;
}

.ico-secondary{
  color: #FF784B!important;
}

.ico-primary{
  color: #002335;
}

.ico-light{
  color: #ffffff;
}

.circle{
  border-radius: 50%!important;
}

.underline-secondary {
  text-decoration: underline;
  text-decoration-color: #ff784b;
  text-decoration-thickness: 4px;
  text-underline-offset: 3px;
}


.btn-secondary .underline-secondary {
  text-decoration-color: #002335;
}


.form-check-input:checked {
  background-color: #ff784b;
  border-color:#ff784b;
}
.form-check-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 120, 75, 0.5); /* Orange glow on focus */
}

.bg-secondary {
  background-color: #ff784b!important;
}

.bg-light {
  background-color: #ffffff!important;
}

.dropdown-item:focus {
  background-color: #ff784b;
}

.fs-7 {
  font-size: 0.875rem; /* 10px */
}

.progress-circle {
  width: 120px; /* Size of the circle */
  height: 120px;
  border-radius: 50%; /* Makes it circular */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: conic-gradient(#ff784b 0%, #e9ecef 0); /* Default gradient */
}

.progress-circle-inner {
  width: 90px; /* Inner circle */
  height: 90px;
  border-radius: 50%;
  background-color: white; /* Inner background */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.readiness-text {
  font-weight: bold;
  color: #002335;
  font-size: 1.2rem;
}


